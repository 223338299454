import { useTranslation } from 'react-i18next';

import { Box, Divider } from '@mui/material';
import cx from 'classnames';
import classNames from 'classnames';

import useStepper from '@components/Stepper/hook';

import './Stepper.scss';
import { ReactComponent as IconTick } from '@images/iconGeneral/tick.svg';

export default function Stepper({ stepNames, currStep }) {
    const { t } = useTranslation();
    const { currStepIndex, camelStepNames } = useStepper(stepNames, currStep);
    const isAllCompleted = currStepIndex === camelStepNames.length - 1;

    const stepClassName = 'stepper' + camelStepNames.length + 'steps';

    return (
        <Box className={classNames(['stepper', stepClassName])}>
            {camelStepNames.map((camelStepName, index) => {
                const isCompletedStep = index < currStepIndex;
                const isCurrIndex = index === currStepIndex;
                const isLastStep = index === camelStepNames.length - 1;
                return (
                    <Box
                        className={cx([
                            'step',
                            { currStep: isCurrIndex },
                            { completedStep: isCompletedStep }
                        ])}
                        key={index}>
                        <Box className="stepItm">
                            <Box className="stepConnector">
                                {!isLastStep && (
                                    <Divider
                                        className="stepHorizontalLine"
                                        orientation="horizontal"
                                    />
                                )}

                                {isAllCompleted ? (
                                    <Box className="stepIndex Finished">
                                        <IconTick className="tickIco" />
                                    </Box>
                                ) : (
                                    <>
                                        {isCompletedStep && (
                                            <Box className="stepIndex stepFinished">
                                                <IconTick className="tickIco" />
                                            </Box>
                                        )}
                                        {isCurrIndex && (
                                            <Box className="stepIndex">
                                                {index + 1}
                                            </Box>
                                        )}
                                        {!isCompletedStep && !isCurrIndex && (
                                            <Box className="stepIndex">
                                                {index + 1}
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Box>

                            <Box className="stepName">
                                {t('common.step.' + camelStepName)}
                            </Box>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
}
